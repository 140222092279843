import React, { useRef } from "react";
import { useState } from "react";
import { motion, AnimateSharedLayout, AnimatePresence } from "framer-motion";
import logo from './logo.svg';
import copy from './copy.svg';
import mailIcon from './paper-plane.svg';
import "./styles.css";

/**
 * This is an example of animating shared layouts in Framer Motion 2.
 *
 * The open state of each panel is contained locally to that component. Wrapping
 * them all in the same AnimateSharedLayout component allows them all to animate
 * in response to state changes that affect each other's layout.
 *
 * Try removing AnimateSharedLayout to see how that affects the animation.
 */


const App = () => {
  const [omMeg, setOmMeg] = useState(false);
  const [erfaring, setErfaring] = useState(false);
  const [kompetanse, setKompetanse] = useState(false);
  const [kontakt, setKontakt] = useState(false);
  const [toasts, setToasts] = useState([]);

  const toastsRef = useRef(toasts);
  toastsRef.current = toasts;

  const showToast = (message) => {
    setToasts(toasts.concat(message));

    setTimeout(() => {
      removeFirstItemFromArray();
    }, 3000)
  };

  const removeFirstItemFromArray = () => {
    var clone = [...toastsRef.current]
    clone.splice(0, 1);
    setToasts(clone);
  }

  const copyToClipboard = str => {
    const el = document.createElement('textarea');
    el.value = str;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    showToast("Kopiert til utklippstavlen");
  };

  return (
    <>
      <div className="content">
        <AnimateSharedLayout>
          <motion.ul layout initial={{ borderRadius: 25 }}>
            <motion.li layout initial={{ borderRadius: 10 }}>
              <header onClick={() => setOmMeg(!omMeg)} className="avatar-wrapper">
                <motion.div layout className="avatar-heading inline-block">Om meg</motion.div>
                <motion.div className="avatar inline-block" layout />
              </header>
              <AnimatePresence>{omMeg && <Content>
                <p >
                  Utdannet sivilingeniør i datateknikk ved NTNU med spesialisering innen software systemutvikling. Jobber med alle lag av løsningene (fullstack) og har lang erfaring innenfor .NET-plattformen.
                </p>
                <p>
                  Selv om jeg anser meg selv som en fullstack-utvikler så har jeg en spesiell interesse for grensesnittprogrammering og gode brukeropplevelser. Jobber gjerne tett opp mot interaksjonsdesignere og bidrar til å realisere effektive løsninger på brukernes premisser.
                  Jeg setter meg raskt inn i nye domener og finner løsninger egnet for den brukergruppen systemet er rettet mot.
                </p>
              </Content>}
              </AnimatePresence>
            </motion.li>
            <motion.li layout initial={{ borderRadius: 10 }}>
              <motion.header layout onClick={() => setKompetanse(!kompetanse)} className="inline-block heading">Kompetanse</motion.header>
              <AnimatePresence>{kompetanse &&
                <Content>
                  <div className="kompetanse">
                    <div>React</div>
                    <div>Typescript</div>
                    <div>.NET Core</div>
                    <div>Git</div>
                    <div>Sass</div>
                    <div>Scrum/Kanban-basert metodikk</div>
                    <div>Azure devops</div>
                  </div>
                </Content>}
              </AnimatePresence>
            </motion.li>
            <motion.li layout initial={{ borderRadius: 10 }}>
              <motion.header layout onClick={() => setKontakt(!kontakt)} className="inline-block heading">Kontakt</motion.header>
              <AnimatePresence>{kontakt &&
                <Content>
                  <div className="email-text">
                    thomas@noren-consulting.no
                  </div>
                  <div className="clipboard-btn" onClick={() => copyToClipboard("thomas@noren-consulting.no")}>
                    Kopier
                    <img className="copy-to-clipboard" alt="copy" src={copy} />
                  </div>
                  <div className="send-epost-btn">

                    <a className="mail" href="mailto:thomas@noren-consulting.no"> Send e-post<img src={mailIcon} alt="send e-post" /></a>
                  </div>
                </Content>}
              </AnimatePresence>
            </motion.li>
            <motion.li layout initial={{ borderRadius: 10 }}>
              <motion.header layout onClick={() => setErfaring(!erfaring)} className="inline-block heading">Prosjekterfaring</motion.header>
              <AnimatePresence>{erfaring &&
                <Content>
                  <div className="project">
                    <img src="dnv_gl.png" alt="dnv gl" />
                    <div>
                      <strong>Kompetanseområde</strong>
                      <p>React, Typescript, three.js, Power BI, Specflow, Azure Devops, .NET Core</p>
                      <strong>Rolle</strong>
                      <p>Fullstack-utvikler</p>
                    </div>
                  </div>
                  <div className="row"></div>
                  <div className="project">
                    <img src="hdir.png" alt="Helsedirektoratet" />
                    <div>
                      <strong>Kompetanseområde</strong>
                      <p>Universell utforming, Scrum, css, HTML5, JS</p>
                      <strong>Rolle</strong>
                      <p>Frontend-utvikler</p>
                    </div>
                  </div>
                  <div className="row"></div>
                  <div className="project">
                    <img src="Tele2.png" alt="Tele2" />
                    <div>
                      <strong>Kompetanseområde</strong>
                      <p>JS, SASS, Umbraco CMS, .NET</p>
                      <strong>Rolle</strong>
                      <p>Frontend-utvikler</p>
                    </div>
                  </div>
                  <div className="row"></div>
                  <div className="project">
                    <img src="Landbruksdirektoratet.png" alt="Landbruksdirektoratet" />
                    <div>
                      <strong>Kompetanseområde</strong>
                      <p>Angular, SASS, GIT, Scrum, Java, universell utforming</p>
                      <strong>Rolle</strong>
                      <p>Fullstack-utvikler</p>
                    </div>
                  </div>
                  <div className="row"></div>

                  <div className="project">
                    <img src="tieto_evry.svg" alt="TietoEvry" />
                    <div>
                      <strong>Kompetanseområde</strong>
                      <p>React, Typescript, Specflow, Azure Devops, .NET Core</p>
                      <strong>Rolle</strong>
                      <p>Fullstack-utvikler</p>
                    </div>
                  </div>

                </Content>}
              </AnimatePresence>
            </motion.li>
          </motion.ul>
        </AnimateSharedLayout>
        <div className="logo">
          <img src={logo} alt="logo" />
        </div>

      </div>
      <div className="toasts">
        {toasts.map((toast, index) => <div key={index} className="toast">{toast}</div>)}
      </div>
    </>
  );
}

const Content = (props) => {
  return (
    <motion.div
      layout
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      {props.children}
    </motion.div>
  );
}



// const Item = (item) => {
//   const [isOpen, setIsOpen] = useState(false);

//   const toggleOpen = () => setIsOpen(!isOpen);

//   return (
//     <motion.li layout onClick={toggleOpen} initial={{ borderRadius: 10 }}>
//       {item.data.avatar && <div className="avatar-wrapper">
//         <motion.div className="avatar inline-block" layout />
//         <motion.div layout className="avatar-heading inline-block">{item.data.heading}</motion.div>
//       </div>}
//       {!item.data.avatar && <motion.header layout className="inline-block heading">{item.data.heading}</motion.header>}
//       <AnimatePresence>{isOpen && <Content data={item.data.content} />}</AnimatePresence>
//     </motion.li>
//   );
// }

// const omMeg = { heading: "Om meg", content: "Utvikler som bor i Drøbak med en master i Datateknikk fra NTNU. Fullstack-utvikler, men koser meg mest med frontend delen av jobben. Har jobber 4 år som konsulent og 3 år som inhouse utvikler siden jeg var ferdig utdannet i 2014.", avatar: true };
// const erfaring = { heading: "Erfaring", content: 'asd', avatar: false };
// const kompetanse = { heading: "Nøkkelkompetanse", content: "bla bla bla", avatar: false };

// const items = [omMeg, erfaring, kompetanse];

export default App;